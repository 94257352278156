import React from 'react';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout>
    <section className="cta">
      <div className="cta-content">
        <div className="container">
          <h2>404.</h2>
          <h3 className="text-white">The requested page was not found.</h3>
          <br />
          <Link className="btn btn-outline btn-xl" to="/">
            Go back
          </Link>
        </div>
      </div>
    </section>
    <Footer />
  </Layout>
);

export default IndexPage;
